import React from "react"
import Banner from "../../molecules/Banner/Banner"
import GalleryGrid from "../GalleryGrid/GalleryGrid"


import { graphql, useStaticQuery } from "gatsby"

const ContentGallery = () => {

  const { allContentfulContenidoGaleria } = useStaticQuery(
    graphql`
        query Gallery_query {
            allContentfulContenidoGaleria {
                nodes {
                    imagenBanner {
                        fluid(maxWidth: 1980) {
                            sizes
                            base64
                            src
                            srcSet
                            aspectRatio
                        }
                        file {
                            url
                        }
                        description
                    }
                    tituloBanner
                    tituloDescripcion
                    descripcion
                    imagenesGaleria {
                        description
                        fluid {
                            aspectRatio
                            base64
                            sizes
                            src
                            srcSet
                        }
                        file {
                            url
                        }
                    }
                }
            }
        }

    `
  )
  const contentInfo = allContentfulContenidoGaleria.nodes[0]

  return (
    <>
      <Banner title={contentInfo.tituloBanner} image={contentInfo.imagenBanner} type="fluid" />
      <GalleryGrid content={contentInfo} />
    </>
  )
}

export default ContentGallery
