import React, { useState } from "react"
import "./GalleryGrid.scss"
import Img from "gatsby-image"
import Modal from "../../molecules/Modal/Modal"


const GalleryGrid = ({ content }) => {

  const [showModalImg, setShowModalImg] = useState(false)
  const [imgToShow, setImgToShow] = useState(0)


  const changeImgSHow = (action) => {
    if (action === "left") {
      if (imgToShow !== 0) {
        setImgToShow(imgToShow - 1)
      }
    }

    if (action === "rigth") {
      if (imgToShow !== content.imagenesGaleria.length - 1) {
        setImgToShow(imgToShow + 1)
      }
    }
  }

  return (
    <>
      {showModalImg &&
      <Modal setShowModal={setShowModalImg} showModal={showModalImg}>
        <div className="img-modal-container">
          {imgToShow !== 0 && (
            <span className="arrow-slide left" onClick={() => changeImgSHow("left")}>
              <i className="fas fa-chevron-left"></i>
            </span>
          )}

          <Img
            className="img-fluid"
            loading="lazy"
            itemProp="image"
            fluid={content.imagenesGaleria[imgToShow].fluid}
            alt={content.imagenesGaleria[imgToShow].description}
            draggable={false}
          />
          {imgToShow !== content.imagenesGaleria.length - 1 && (

            <span className="arrow-slide rigth" onClick={() => changeImgSHow("rigth")}>
          <i className="fas fa-chevron-right"></i>
          </span>
          )}

        </div>
      </Modal>
      }
      <div className="gallery">
        <div className="gallery__wrapper">
          <div className="gallery__info">
            <h2 className="gallery__info-title">{content.tituloDescripcion}</h2>
            <p className="gallery__info-paragraph">{content.descripcion}</p>
          </div>
          <div className="gallery__grid">
            {content.imagenesGaleria.map((img, index) => {
              return (
                <div
                  key={index}
                  className="gallery__grid-img"
                  onClick={() => {
                    setShowModalImg(true)
                    setImgToShow(index)
                  }}>
                  <Img
                    className="img-fluid"
                    loading="lazy"
                    itemProp="image"
                    fluid={img.fluid}
                    alt={img.description}
                    draggable={false}
                  />
                </div>
              )
            })}
          </div>
        </div>

      </div>
    </>
  )
}

export default GalleryGrid
