import React from "react"
import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import ContentGallery from "../components/organisms/ContentGallery/ContentGallery"
import { graphql } from "gatsby"

const Galeria = (props) => {

  const { allContentfulContenidoGaleria } = props.data


  return (
    <Layout>
      <SEO
        title={allContentfulContenidoGaleria.nodes[0].metaTitulo}
        description={allContentfulContenidoGaleria.nodes[0].metaDescription.metaDescription}
      />
      <ContentGallery />
    </Layout>
  )
}

export const query = graphql`
    query Meta_Query_Gallery{
        allContentfulContenidoGaleria {
            nodes {
                metaTitulo
                metaDescription {
                    metaDescription
                }
            }
        }
    }
`

export default Galeria
